import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import ReactInfiniteScroll from 'react-infinite-scroller';
import { isEmpty } from 'ramda';

class InfiniteScroll extends PureComponent {
  // NOTE: Use this InfiniteScroll component if using Redux, and bring in ReactInfiniteScroll if using getHooksForRepository
  static propTypes = {
    children: PropTypes.node.isRequired,
    setRef: PropTypes.func.isRequired,
    loadMore: PropTypes.func.isRequired,
    meta: PropTypes.shape().isRequired,
    loading: PropTypes.bool.isRequired,
    className: PropTypes.string,
  };

  static defaultProps = {
    className: '',
  };

  state = {
    currentPageLoading: false,
  };

  handleLoadMore = page => {
    this.setState({ currentPageLoading: true });
    this.props.loadMore(page).then(() => this.setState({ currentPageLoading: false }));
  };

  hasMorePages = (meta, loading) => {
    if (loading) {
      return false;
    }
    if (!isEmpty(meta) && meta !== undefined) {
      const { currentPage, numPages } = meta;
      return currentPage < numPages;
    }
    return true;
  };

  hasMore = (meta, loading) => {
    if (this.state.currentPageLoading) {
      return false;
    }
    return this.hasMorePages(meta, loading);
  };

  render() {
    const { children, setRef, className, meta, loading, ...rest } = this.props;

    return (
      <ReactInfiniteScroll
        className={className}
        hasMore={this.hasMore(meta, loading)}
        ref={setRef}
        {...rest}
        loadMore={this.handleLoadMore}
      >
        {children}
      </ReactInfiniteScroll>
    );
  }
}

export default InfiniteScroll;
